<template>
    <div class="w-full py-5">
        <div class="container sm:block md:flex block mb-0">
            <div class="sm:w-full md:w-1/2 w-full">
                <img :src="data.Image" class="w-full" alt="">
            </div>
            <div class="sm:w-full md:w-1/2 w-full block my-auto sm:p-0 md:px-5 p-0 text-center">
                <span class="text-3xl font-bold">{{ data.Title }}</span>
                <p>{{ data.Description }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
